import React from 'react';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image'
import { graphql, PageProps, Link } from 'gatsby';
import { readingTime } from 'reading-time-estimator';
import SEO from '../components/seo';
import Layout from '../components/layout';
import TableOfContents from '../components/tableOfContents';
import FormattedPortableText from '../components/formattedPortableText';
import twitter from '../assets/images/social/twitter.svg'
import facebook from '../assets/images/social/facebook.svg'
import linkedin from '../assets/images/social/linkedin.svg'
import link from '../assets/images/social/link.svg'
import background from '../assets/svgs/case-study-banner.svg'



export interface rawDataNode {
  style: string;
  text: string;
  children: rawDataNode[];
  key: string;
  _type: string;
}

const relatedCaseStudy = (caseStudy: Queries.CaseStudyPageLayoutQuery['sanityCaseStudyPage']['relatedCaseStudies'][0]) => {
  const { title, subjectName, _createdAt, mobileSubjectImage, mobileSubjectHeadshot, content, slug } = caseStudy;
  const readTime = readingTime(content.reduce((acc, { children }) => acc + children.map(({ text }) => text).join(' '), '')).text;
  const dateString = new Date(_createdAt).toLocaleDateString('en-us', { year:"numeric", day:"numeric", month:"short"});
  return (
    <div className='related-study' key={title}>
      <Link className='related-link' to={slug.current}>
        <GatsbyImage image={mobileSubjectImage.asset.gatsbyImageData} alt={title}/>
        <div className='title'>{title}</div>
      </Link>
      <div className='related-study-metadata'>
        <GatsbyImage image={mobileSubjectHeadshot.asset.gatsbyImageData} alt={title}/>
        <div>
          <div className='author'>{subjectName}</div>
          <div className='date'>{`${dateString} · ${readTime}`}</div>
        </div>
      </div>
    </div>
  )
}



export default function PageTemplate({data}:PageProps<Queries.CaseStudyPageLayoutQuery>) {
  const {
    rawContent,
    title,
    subTitle,
    seo,
    products,
    useCases,
    industries,
    caseStudyHighlights,
    webSubjectImage,
    mobileSubjectHeadshot,
    mobileSubjectImage,
    subjectName,
    subjectTitle,
    relatedCaseStudies,
    additionalResources,
  } = data.sanityCaseStudyPage;
  const { title: seoTitle, rawDescription: seoDescription, openGraphImage } = seo || {}

  const copy = () => {
    if (typeof window === 'undefined') return;
    navigator.clipboard.writeText(window.location.toString());
    // eslint-disable-next-line no-alert
    window.alert('Link copied to clipboard');
  };

  const location = typeof window !== 'undefined' ? window.location.toString() : '';

  return (
    <Layout>
      <SEO
        image={openGraphImage && getSrc(openGraphImage.asset.gatsbyImageData)}
        title={seoTitle}
        description={seoDescription}
      />
      <div className='case-study'>
        <div className='image-banner' style={{ backgroundImage: `url(${background})` }}>
          <div className='badge' >Case Study</div>
          <h1>{title}</h1>
          <h2>{subTitle}</h2>
        </div>
        <div className='body'>
          <div className='main'>
            <div className='side-bar'>
              <div className='tag-wrapper'>
                <div className='tag'>
                  <div className='tag-label'>Products:</div>
                  <div className='tag-text'>
                    {products.map((product) => (
                      <div key={product}>{product}</div>
                    ))}
                  </div>
                </div>
                <div className='tag'>
                  <div className='tag-label'>Use Cases:</div>
                  <div className='tag-text'>
                    {useCases.map((useCase) => (
                      <div key={useCase}>{useCase}</div>
                    ))}
                  </div>
                </div>
                <div className='tag'>
                  <div className='tag-label'>Industry:</div>
                  <div className='tag-text'>
                    {industries.map((industry) => (
                      <div key={industry}>{industry}</div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='sticky'>
                <TableOfContents rawContent={rawContent} headerClassName='side-bar-title' className='side-bar-item' itemClasses={{
                  setClassName: 'side-bar-set',
                  entryClassName: 'side-bar-entry',
                  subEntryClassName: 'side-bar-sub-entry'
                }} />
                <div className='side-bar-item display-md-up'>
                  <p className='side-bar-title'> Additional Resources</p>
                    {additionalResources.map(({ url, text }) => (
                      <span key={url.current} className='side-bar-set'>
                        {url.current.includes('http') ?
                        <a className='side-bar-entry' href={url.current}>
                          {text}
                        </a> :
                        <Link className='side-bar-entry' to={url.current}>
                          {text}
                        </Link>}
                      </span>
                    ))}
                </div>
                <div className='side-bar-item display-md-up'>
                  <p className='side-bar-title'>Share</p>
                  <div className='share-buttons'>
                    <button type='button' onClick={copy}>
                      <img src={link} alt='copy' />
                    </button>
                    <a href={`https://twitter.com/intent/tweet?text=${location}`}>
                      <img src={twitter} alt='twitter' />
                    </a>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${location}`}>
                      <img src={facebook} alt='facebook' />
                    </a>
                    <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${location}`}>
                      <img src={linkedin} alt='linkedin' />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='content'>
              <div className='subject-image'>
                <div className='display-md-up'>
                  <GatsbyImage image={webSubjectImage.asset.gatsbyImageData} alt={title}/>
                </div>
                <div className='display-md-down'>
                  <GatsbyImage image={mobileSubjectImage.asset.gatsbyImageData} alt={title}/>
                  <div className='mobile-subject-info'>
                    <GatsbyImage image={mobileSubjectHeadshot.asset.gatsbyImageData} alt={title}/>
                    <div>
                      <div className='subject-name'>{subjectName}</div>
                      <div className='subject-title'>{subjectTitle}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='highlights'>
                {caseStudyHighlights.map((highlight) => (
                  <div key={highlight.text} className='highlight'>
                    <div className='highlight-text'>{highlight.text}
                      <span className='highlight-superscript'>{highlight.superscript}</span>
                    </div>
                    <div className='highlight-subtext'>{highlight.subText}</div>
                  </div>
                ))}
              </div>
              <FormattedPortableText rawContent={rawContent} />
            </div>
          </div>
          <div className='line display-md-up'/>
          <div className='related-label'>Related case studies</div>
          <div className='related'>
            {relatedCaseStudies.map(relatedCaseStudy)}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
query CaseStudyPageLayout($id: String) {
  sanityCaseStudyPage(id: {eq: $id}) {
    slug {
      current
    }
    rawContent: _rawContent
    title,
    subTitle,
    webSubjectImage {
      asset {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    },
    mobileSubjectImage {
      asset {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    },
    mobileSubjectHeadshot {
      asset {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    },
    subjectName,
    subjectTitle,
    caseStudyHighlights {
      text,
      subText,
      superscript
    },
    products,
    useCases,
    industries,
    additionalResources {
      text,
      url {
        current
      },
    },
    relatedCaseStudies {
      title,
      subjectName,
      _createdAt,
      slug {
        current
      }
      content {
        children {
          text
        }
      }
      mobileSubjectHeadshot {
        asset {
          gatsbyImageData(placeholder: NONE, height: 40)
        }
      },
      mobileSubjectImage {
        asset {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      },
    }
    seo {
      keywords
      title
      rawDescription:  _rawDescription
      openGraphImage {
        asset {
          gatsbyImageData
        }
      }
    }
  }
}
`
